<template>
    <!-- Jumbotron -->
    <div
     class="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center"
     style="background-image: url('https://tecdn.b-cdn.net/img/new/slides/041.webp'); height: 250px">
     <div
       class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
       style="background-color: rgba(0, 0, 0, 0.6)">
       <div class="flex h-full items-center justify-center">
         <div class="text-white">
           <h2 class="mb-4 text-6xl font-semibold">Cookies Policy</h2>
         </div>
       </div>
     </div>
   </div>
   <!-- End Jumbotron -->
   <div class="container mx-auto px-4">
    <h1 class="text-2xl font-bold my-4">Cookie Policy for Would you rather?</h1>

    <p class="mb-4">This is the Cookie Policy for Would you rather?, accessible from https://wouldurather.net</p>

    <h2 class="text-xl font-semibold my-3"><strong>What Are Cookies</strong></h2>

    <p class="mb-4">As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>

    <h2 class="text-xl font-semibold my-3"><strong>How We Use Cookies</strong></h2>

    <p class="mb-4">We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there are no industry-standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>

    <h2 class="text-xl font-semibold my-3"><strong>Disabling Cookies</strong></h2>

    <p class="mb-4">You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore, it is recommended that you do not disable cookies. This Cookies Policy was created with the help of the <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/" class="text-blue-600 hover:text-blue-800">Cookies Policy Generator</a>.</p>

    <h2 class="text-xl font-semibold my-3"><strong>The Cookies We Set</strong></h2>

    <ul class="list-disc pl-5 mb-4">
        <li>
            <p class="font-semibold">Site preferences cookies</p>
            <p>In order to provide you with a great experience on this site, we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences, we need to set cookies so that this information can be called whenever you interact with a page that is affected by your preferences.</p>
        </li>
    </ul>

    <h2 class="text-xl font-semibold my-3"><strong>Third Party Cookies</strong></h2>

    <p class="mb-4">In some special cases, we also use cookies provided by trusted third parties. The following section details which third-party cookies you might encounter through this site.</p>

    <ul class="list-disc pl-5 mb-4">
        <!-- List items for third-party cookies -->
    </ul>

    <h2 class="text-xl font-semibold my-3"><strong>More Information</strong></h2>

    <p class="mb-4">However, if you are still looking for more information, then you can contact us through one of our preferred contact methods:</p>

    <ul class="list-disc pl-5 mb-4">
        <li>Email: <a href="mailto:hello@codebuzz.eu" class="text-blue-600 hover:text-blue-800">hello@codebuzz.eu</a></li>
    </ul>
</div>

  </template>
  
  <script>
  export default {
    name: 'CookiesPolicyComponent'
  }
  </script>
  