import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from '../components/HomeComponent.vue';
import AboutComponent from '../components/AboutComponent.vue';
import ContactComponent from '../components/ContactComponent.vue';
import PrivacyPolicyComponent from '../components/PrivacyPolicyComponent.vue';
import CookiesPolicyComponent from '../components/CookiesPolicyComponent.vue';
import AddQuestionComponent from '../components/AddQuestionComponent.vue';
import HowToPlayComponent from '../components/HowToPlayComponent.vue';
import TermsOfServiceComponent from '../components/TermsOfServiceComponent.vue';
import BlogComponent from '../components/BlogComponent.vue';
import PostComponent from '../components/PostComponent.vue';
import NotFoundComponent from '../components/NotFoundComponent.vue';

const routes = [
  { path: '/', name: 'Home', component: HomeComponent },
  { path: '/:lang', name: 'HomeWithLang', component: HomeComponent },
  { path: '/how-to-play', name: 'HowToPlay', component: HowToPlayComponent },
  { path: '/about', name: 'About', component: AboutComponent },
  { path: '/contact', name: 'Contact', component: ContactComponent },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicyComponent },
  { path: '/cookies-policy', name: 'CookiesPolicy', component: CookiesPolicyComponent },
  { path: '/blog', name: 'Blog', component: BlogComponent },
  { path: '/blog/:id', name: 'Post', component: PostComponent, props: true },
  { path: '/terms-of-service', name: 'TermsOfService', component: TermsOfServiceComponent },
  { path: '/add-question', name: 'AddQuestion', component: AddQuestionComponent },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundComponent }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;