<template>
  <footer class="bg-white dark:bg-gray-900">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0 flex flex-col md:flex-row items-center">
          <span class="ml-4 dark:text-white">{{ $t('footer.copyright', { year: currentYear }) }}</span>
        </div>
        <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>
            <router-link to="/terms-of-service" class="text-gray-900 dark:text-white hover:text-gray-400">{{ $t('footer.termsOfService') }}</router-link>
          </div>
          <div>
            <router-link to="/privacy-policy" class="text-gray-900 dark:text-white hover:text-gray-400">{{ $t('footer.privacyPolicy') }}</router-link>
          </div>
          <div>
            <router-link to="/cookies-policy" class="text-gray-900 dark:text-white hover:text-gray-400">{{ $t('footer.cookiesPolicy') }}</router-link>
          </div>
        </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">{{ $t('footer.partOf') }}
          <a href="http://codebuzz.eu" class="hover:underline text-gray-500 codebuzz-link"> Codebuzz</a>
        </span>
        <!-- Social media links (optional) -->
        <div class="flex mt-4 sm:justify-center sm:mt-0">
          <!-- Add social media icons here if needed -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style>
.codebuzz-link {
  font-family: 'Pacifico', cursive;
}
</style>