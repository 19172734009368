<template>
    <!-- Jumbotron -->
    <div
     class="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center"
     style="background-image: url('https://tecdn.b-cdn.net/img/new/slides/041.webp'); height: 250px">
     <div
       class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
       style="background-color: rgba(0, 0, 0, 0.6)">
       <div class="flex h-full items-center justify-center">
         <div class="text-white">
           <h2 class="mb-4 text-6xl font-semibold">Privacy policy</h2>
         </div>
       </div>
     </div>
   </div>
   <!-- End Jumbotron -->
   </template>

<script>
export default {
  name: 'PrivacyPolicyComponent'
  // You can add your script here if needed
}
</script>

<style>
/* Additional styles can be added here if needed */
</style>
