<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-purple-900 to-indigo-900 p-4 relative overflow-hidden">
    <!-- Animated background -->
    <div class="absolute inset-0">
      <div v-for="i in 50" :key="i" class="star"></div>
    </div>
    
    <!-- Main content -->
    <div v-if="currentQuestion" class="text-center relative z-10 w-full max-w-7xl flex flex-col items-center justify-center h-full">
      <h2 class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4 sm:mb-8">{{ $t('home.wouldYouRather') }}</h2>
      <div ref="cardsContainer" class="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8 w-full mb-4 sm:mb-8">
        <div ref="option1" class="option card-style left-card w-full sm:w-2/5 h-48 sm:h-64 md:h-80" @click="voteMade ? transitionToNextQuestion() : vote('option_1')">
          <div class="card-inner p-4">
            <p class="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">{{ localizedOption1 }}</p>
            <transition name="fade">
              <div v-if="voteMade" class="mt-2 sm:mt-4">
                <p class="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">{{ animatedOption1Percentage }}%</p>
                <p class="text-sm sm:text-base md:text-lg lg:text-xl votes-count mt-1 sm:mt-2">{{ currentQuestion.option_1_votes }} {{ $t('home.votesCount') }}</p>
              </div>
            </transition>
          </div>
        </div>

        <div class="or-circle text-base sm:text-lg md:text-xl my-2 sm:my-0">OR</div>

        <div ref="option2" class="option card-style right-card w-full sm:w-2/5 h-48 sm:h-64 md:h-80" @click="voteMade ? transitionToNextQuestion() : vote('option_2')">
          <div class="card-inner p-4">
            <p class="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold">{{ localizedOption2 }}?</p>
            <transition name="fade">
              <div v-if="voteMade" class="mt-2 sm:mt-4">
                <p class="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">{{ animatedOption2Percentage }}%</p>
                <p class="text-sm sm:text-base md:text-lg lg:text-xl votes-count mt-1 sm:mt-2">{{ currentQuestion.option_2_votes }} {{ $t('home.votesCount') }}</p>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <transition name="fade">
          <button
            v-if="voteMade"
            @click="transitionToNextQuestion"
            class="mt-4 bg-gradient-to-r from-pink-500 to-orange-500 hover:from-pink-600 hover:to-orange-600 text-white font-bold text-lg sm:text-xl py-2 px-6 rounded-full shadow-lg transform hover:scale-105 transition duration-300 flex items-center justify-center"
          >
            {{ $t('home.next') }} <span class="ml-2">→</span>
          </button>
        </transition>
      </div>
    </div>
    <div v-else class="text-white relative z-10">
      <p class="text-3xl">{{ $t('home.loadingQuestion') }}</p>
      <div class="loader mt-6"></div>
    </div>
  </div>
</template>

<script>
import { supabase } from '@/supabase.js';

export default {
  name: 'HomeComponent',
  data() {
    return {
      currentQuestion: null,
      voteMade: false,
      animatedOption1Percentage: 0,
      animatedOption2Percentage: 0,
      loading: false
    };
  },
  computed: {
    localizedOption1() {
      return this.currentQuestion ? this.currentQuestion.option_1 : '';
    },
    localizedOption2() {
      return this.currentQuestion ? this.currentQuestion.option_2 : '';
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.fetchRandomQuestion();
      }
    }
  },
  mounted() {
    this.fetchRandomQuestion();
    document.addEventListener('keydown', this.handleEnterKey);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleEnterKey);
  },
  methods: {
    getLocalizedName(key) {
      const localeSuffix = this.$i18n.locale === 'en' ? '' : `_${this.$i18n.locale}`;
      return this.currentQuestion ? this.currentQuestion[key + localeSuffix] : '';
    },
    async fetchRandomQuestion() {
      this.loading = true;
      const tableName = `questions_${this.$i18n.locale}`;

      try {
        const { count } = await supabase
          .from(tableName)
          .select('*', { count: 'exact', head: true })
          .eq('published', true);

        if (count) {
          const randomIndex = Math.floor(Math.random() * count);
          const { data, error } = await supabase
            .from(tableName)
            .select('id, option_1, option_2, option_1_votes, option_2_votes')
            .eq('published', true)
            .range(randomIndex, randomIndex);

          if (error) throw error;
          
          this.currentQuestion = data[0];
        } else {
          console.error(`No questions available in ${tableName}`);
          this.currentQuestion = null;
        }
      } catch (error) {
        console.error('Error fetching random question:', error);
        this.currentQuestion = null;
      } finally {
        this.loading = false;
      }
    },
    animatePercentage(option, targetPercentage) {
      let currentPercentage = 0;
      const interval = setInterval(() => {
        currentPercentage++;
        if (option === 'option_1') {
          this.animatedOption1Percentage = currentPercentage;
        } else {
          this.animatedOption2Percentage = currentPercentage;
        }

        if (currentPercentage >= targetPercentage) {
          clearInterval(interval);
        }
      }, 20);
    },
    handleEnterKey(event) {
      if (event.key === 'Enter' && this.voteMade) {
        this.transitionToNextQuestion();
      }
    },
    calculatePercentage(votes) {
      const totalVotes = this.currentQuestion.option_1_votes + this.currentQuestion.option_2_votes;
      return totalVotes > 0 ? Math.round((votes / totalVotes) * 100) : 0;
    },
    async vote(option) {
      if (this.voteMade) return;

      const tableName = `questions_${this.$i18n.locale}`;
      const column = `${option}_votes`;
      const newVoteCount = this.currentQuestion[column] + 1;

      const { data, error } = await supabase
        .from(tableName)
        .update({ [column]: newVoteCount })
        .eq('id', this.currentQuestion.id)
        .select();

      if (error) {
        console.error('Error updating votes:', error);
      } 
      if (data) {
        this.voteMade = true;
        this.currentQuestion[column] = newVoteCount;
        const percentage1 = this.calculatePercentage(this.currentQuestion.option_1_votes);
        const percentage2 = this.calculatePercentage(this.currentQuestion.option_2_votes);
        this.animatePercentage('option_1', percentage1);
        this.animatePercentage('option_2', percentage2);
        if (option === 'option_1') {
          this.$refs.option1.classList.add('selected');
        } else if (option === 'option_2') {
          this.$refs.option2.classList.add('selected');
        }
      }
    },
    async nextQuestion() {
      if (this.$refs.option1) {
        this.$refs.option1.classList.remove('selected');
      }
      if (this.$refs.option2) {
        this.$refs.option2.classList.remove('selected');
      }
      this.voteMade = false;
      await this.fetchRandomQuestion();
    },
    async transitionToNextQuestion() {
      this.applyFadeOut();
      await this.waitForAnimation();
      await this.nextQuestion();
      this.$nextTick(() => {
        this.applyFadeIn();
      });
    },
    applyFadeOut() {
      this.$refs.cardsContainer.classList.add('fade-out');
    },
    applyFadeIn() {
      const cardsContainer = this.$refs.cardsContainer;
      cardsContainer.classList.remove('fade-out');
      cardsContainer.classList.add('fade-in');

      const onFadeInEnd = () => {
        cardsContainer.classList.remove('fade-in');
        cardsContainer.removeEventListener('transitionend', onFadeInEnd);
      };

      cardsContainer.addEventListener('transitionend', onFadeInEnd);
    },
    waitForAnimation() {
      return new Promise(resolve => setTimeout(resolve, 500));
    }
  }
};
</script>



<style scoped>
.bg-gradient-to-br {
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  animation: twinkle 5s infinite;
  opacity: 0;
}

@keyframes twinkle {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.card-style {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  border-radius: 1rem;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-style:hover .card-inner {
  transform: rotateY(10deg);
}

.left-card .card-inner {
  background: linear-gradient(135deg, #0c61b0, #0056a4);
}

.right-card .card-inner {
  background: linear-gradient(135deg, #ff3037, #e62d32);
}

.or-circle {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.option.selected .card-inner {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media (min-width: 640px) {
  .or-circle {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .or-circle {
    width: 60px;
    height: 60px;
  }
}
</style>