<template>
    <div class="not-found flex flex-col items-center justify-center h-screen">
      <h1 class="text-9xl font-bold">404</h1>
      <p class="text-lg mb-4">{{ $t("notFound.message") }}</p>
      <router-link 
        to="/" 
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        {{ $t("notFound.goBackHome") }}
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundComponent'
  }
  </script>
  
  <style>
  .not-found {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  