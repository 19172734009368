<template>
  <section class="py-12 bg-gray-100">
    <div class="container mx-auto px-6">
      <h2 class="text-4xl font-bold text-center text-gray-800 mb-8">
        Blog Posts
      </h2>
      <div class="flex flex-wrap">
        <div v-for="post in posts" :key="post.id" class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="bg-white h-full shadow-sm rounded-md overflow-hidden">
            <img :src="post.featured_image" alt="Blog cover" class="w-full h-56 object-cover object-center">
            <div class="p-4">
              <h3 class="font-bold text-xl text-gray-800">
                {{ post.title }}
              </h3>
              <p class="text-gray-600 mt-2">
                {{ post.excerpt }}
              </p>
              <router-link :to="`/blog/${post.id}`" class="inline-block mt-4 text-blue-500 hover:text-blue-600">
                Read More
              </router-link>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Import your database client, e.g., Supabase
import { supabase } from '@/supabase.js';

export default {
  name: 'BlogComponent',
  data() {
    return {
      posts: []
    };
  },
  async created() {
    await this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      try {
        const { data, error } = await supabase
          .from('blog_posts') // Replace 'posts' with your actual table name
          .select('*');
        
        if (error) {
          console.error('Error fetching posts:', error);
        } else {
          this.posts = data;
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    }
  }
};
</script>
