<template>
    <!-- Jumbotron -->
    <div class="relative bg-cover bg-no-repeat text-center text-white py-12 px-4"
     :style="{ backgroundImage: 'url(' + imageUrl + ')', backgroundPosition: 'center center' }" 
     style="height: 450px;">
        <div class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
            <h2 class="text-6xl font-semibold">"Would You Rather" - A Guide to the Game</h2>
        </div>
    </div>
    <!-- End Jumbotron -->

    <!-- Instructions Section -->
    <section class="mt-8 px-4">
        <div class="text-center">
            <h3 class="text-3xl font-semibold mb-4">Introduction</h3>
            <article class="max-w-4xl mx-auto text-left">
                <p class="mb-4">"Would You Rather" is a delightful and straightforward game that can be played by people of all ages. It's a fantastic way to engage in conversations and learn more about each other's preferences and thoughts. There are no complex rules or equipment needed, just a group of people ready to explore fun or challenging scenarios.</p>
                
                <h4 class="text-2xl font-semibold mb-2">Setting Up the Game:</h4>
                <ul class="list-disc pl-6 mb-4">
                    <li>Formation: Arrange yourselves comfortably, either seated around a table or in a circle, ensuring everyone can participate and hear clearly.</li>
                    <li>Initiating the Game: The game can start with any player. Often, the youngest participant is chosen to go first, or you could use any random method, like a coin toss.</li>
                    <li>Duration of Play: Decide beforehand how long you want to play. Options include a set number of rounds, a specific time duration, or simply going on until you've exhausted your questions.</li>
                    <li>Game Briefing: If there are newcomers to the game, take a moment to explain the rules and the nature of the game to ensure everyone's on the same page.</li>
                </ul>

                <h4 class="text-2xl font-semibold mb-2">Playing "Would You Rather":</h4>
                <ul class="list-disc pl-6 mb-4">
                    <li>Kicking Off: The first player poses a "Would You Rather" question, offering two distinct choices (e.g., "Would you rather have the ability to time travel or be invisible?").</li>
                    <li>Responding: Each participant then selects one of the two options. It's important to note that choosing both or neither is not allowed.</li>
                    <li>Delving Deeper (Optional): Players can optionally elaborate on their choices. This step adds depth to the game and can lead to fascinating discussions and insights.</li>
                    <li>Continuing the Game: Play proceeds to the next person, who then asks a new question. The sequence continues in this manner.</li>
                </ul>

                <h4 class="text-2xl font-semibold mb-2">Conclusion:</h4>
                <p>Remember, in "Would You Rather", the journey is the reward. There are no points or competitive elements; it's all about sharing, learning, and enjoying the company. This game is an excellent tool for breaking the ice in various gatherings, be it with friends, family members, or colleagues.</p>
            </article>
        </div>
    </section>
    <!-- End Instructions Section -->

   </template>
   
   <script>
   export default {
     name: 'HowToPlayComponent',
     data() {
        return {
            imageUrl: require('@/assets/friends_playing_1.jpeg')
        }
     }
   }
   </script>
   
   <style scoped>
   /* Additional styles can be added here if needed */
   </style>
   