<template>
  <nav class="bg-white shadow-md" @click="handleClickOutside">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-20">
        <!-- Logo and Title -->
        <div class="flex-shrink-0 flex items-center">
          <router-link :to="{ name: 'Home', params: { lang: currentLocale.code } }" class="flex items-center space-x-3">
            <img :src="require('@/assets/logo.png')" alt="Logo" class="h-10 w-10" />
            <span class="font-bold text-2xl text-gray-800">Would You Rather?</span>
          </router-link>
        </div>

        <!-- Navigation Links (desktop) -->
        <div class="hidden md:flex items-center space-x-6">
          <router-link :to="localizedRoute('HowToPlay')" class="text-gray-600 hover:text-indigo-600 px-3 py-2 text-lg font-bold transition duration-300">
            {{ $t('navbar.howToPlay') }}
          </router-link>
          <router-link :to="localizedRoute('Blog')" class="text-gray-600 hover:text-indigo-600 px-3 py-2 text-lg font-bold transition duration-300">
            {{ $t('navbar.blog') }}
          </router-link>
          <router-link :to="localizedRoute('AddQuestion')" class="add-question-link bg-indigo-600 text-white hover:bg-indigo-700 px-4 py-2 rounded-md text-lg font-bold transition duration-300">
            {{ $t('navbar.addQuestion') }}
          </router-link>
          <div class="relative">
            <button @click.stop="toggleLanguages" class="flex items-center space-x-2 text-gray-600 hover:text-indigo-600 px-3 py-2 text-lg font-bold transition duration-300">
              <img :src="currentLocale.flag" class="w-5 h-5 rounded-full" :alt="currentLocale.name"/>
              <span>{{ currentLocale.name }}</span>
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            <div v-if="showLanguages" class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5" @click.stop>
              <a v-for="locale in locales" 
                 :key="locale.code"
                 @click="switchLanguage(locale.code)"
                 class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer">
                <img :src="locale.flag" class="w-5 h-5 rounded-full mr-3" :alt="locale.name">
                <span>{{ locale.name }}</span>  
              </a>
            </div>
          </div>
        </div>

        <!-- Mobile menu button -->
        <div class="md:hidden">
          <button @click="toggleMenu" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open main menu</span>
            <svg class="h-6 w-6" :class="{'hidden': menuOpen, 'block': !menuOpen }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg class="h-6 w-6" :class="{'block': menuOpen, 'hidden': !menuOpen }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div :class="{'block': menuOpen, 'hidden': !menuOpen}" class="md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <router-link :to="localizedRoute('HowToPlay')" class="text-gray-600 hover:bg-gray-50 hover:text-indigo-600 block px-3 py-2 rounded-md text-base font-bold">
          {{ $t('navbar.howToPlay') }}
        </router-link>
        <router-link :to="localizedRoute('Blog')" class="text-gray-600 hover:bg-gray-50 hover:text-indigo-600 block px-3 py-2 rounded-md text-base font-bold">
          {{ $t('navbar.blog') }}
        </router-link>
        <router-link :to="localizedRoute('AddQuestion')" class="add-question-link bg-indigo-600 text-white hover:bg-indigo-700 block px-3 py-2 rounded-md text-base font-bold">
          {{ $t('navbar.addQuestion') }}
        </router-link>
        <div class="relative px-3 py-2">
          <button @click="toggleLanguages" class="flex items-center w-full text-left text-gray-600 hover:text-indigo-600">
            <img :src="currentLocale.flag" class="w-5 h-5 rounded-full mr-3" :alt="currentLocale.name"/>
            <span>{{ currentLocale.name }}</span>
          </button>
          <div v-if="showLanguages" class="mt-2 space-y-2">
            <a v-for="locale in locales" 
               :key="locale.code"
               @click="switchLanguage(locale.code)"
               class="flex items-center px-3 py-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-indigo-600 cursor-pointer">
              <img :src="locale.flag" class="w-5 h-5 rounded-full mr-3" :alt="locale.name">
              <span>{{ locale.name }}</span>  
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useHead } from '@vueuse/head';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { locale, t } = useI18n();

    const menuOpen = ref(false);
    const showLanguages = ref(false);

    const locales = [
      { name: 'English', code: 'en', flag: 'https://flagcdn.com/w320/gb.png' },
      { name: 'Deutsch', code: 'de', flag: 'https://flagcdn.com/w320/de.png' },
      { name: 'polski', code: 'pl', flag: 'https://flagcdn.com/w320/pl.png' },
      { name: 'français', code: 'fr', flag: 'https://flagcdn.com/w320/fr.png' },
      { name: 'español', code: 'es', flag: 'https://flagcdn.com/w320/es.png' },
      { name: 'português', code: 'pt', flag: 'https://flagcdn.com/w320/pt.png' }
    ];

    const currentLocale = computed(() => {
      return locales.find(l => l.code === locale.value) || locales[0];
    });

    const localizedRoute = (name, params = {}) => {
      return {
        name: name,
        params: { ...params, lang: currentLocale.value.code }
      };
    };


    const switchLanguage = (newLocale) => {
      locale.value = newLocale;

      // Get the current path segments
      const pathSegments = route.path.split('/').filter(segment => segment !== '');

      // Check if the first segment is a valid locale
      const isFirstSegmentLocale = locales.some(locale => locale.code === pathSegments[0]);

      // Construct the new path
      let newPath;
      if (isFirstSegmentLocale) {
        // Replace the first segment with the new locale
        pathSegments[0] = newLocale;
        newPath = '/' + pathSegments.join('/');
      } else {
        // Add the new locale to the beginning of the path
        newPath = '/' + newLocale + route.path;
      }

      // Push the new route
      router.push({
        path: newPath,
        query: route.query,
        hash: route.hash
      });

      showLanguages.value = false;
      updateMetaTags();
    };

    const updateMetaTags = () => {
      useHead({
        title: t('seo.title'),
        meta: [
          { name: 'description', content: t('seo.description') },
          { name: 'keywords', content: t('seo.keywords') },
          { property: 'og:title', content: t('seo.ogTitle') },
          { property: 'og:description', content: t('seo.ogDescription') },
          { name: 'twitter:title', content: t('seo.twitterTitle') },
          { name: 'twitter:description', content: t('seo.twitterDescription') },
        ],
      });
    };

    const toggleLanguages = () => {
      showLanguages.value = !showLanguages.value;
    };

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
    };

    const handleClickOutside = (event) => {
      const dropdown = document.querySelector('.relative .absolute');
      if (dropdown && !dropdown.contains(event.target)) {
        showLanguages.value = false;
      }
    };

    watch(() => route.params.lang, (newLang) => {
      if (newLang && newLang !== locale.value) {
        locale.value = newLang;
        updateMetaTags();
      }
    });

    onMounted(() => {
      const routeLang = route.params.lang;
      if (routeLang && routeLang !== locale.value) {
        locale.value = routeLang;
      } else if (!routeLang) {
        // If no language in URL, add the current locale to the URL
        const newPath = `/${locale.value}${route.path}`;
        router.replace({ path: newPath, query: route.query, hash: route.hash });
      }
      updateMetaTags();
      document.addEventListener('click', handleClickOutside);
    });

    return {
      menuOpen,
      showLanguages,
      currentLocale,
      locales,
      switchLanguage,
      toggleLanguages,
      toggleMenu,
      localizedRoute,
    };
  },
};
</script>

<style scoped>
nav {
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}

nav:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.router-link-active {
  color: #4f46e5; /* Indigo-600 */
  font-weight: 600;
}

.add-question-link.router-link-active {
  color: white; /* Maintain white color for active "Add Question" link */
}
</style>
