<template>
    <div>
      <h1>Contact Us</h1>
      <p>This is the contact page content.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactComponent'
  }
  </script>
  