<template>
  <div id="app">
    <NavbarComponent @language-changed="updateLanguage" />
    <router-view :key="currentLocale" />
    <FooterComponent />
  </div>
</template>

<script>
import { ref, provide } from 'vue';
import NavbarComponent from './components/NavbarComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
    FooterComponent
  },
  setup() {
    const currentLocale = ref('en'); // Default language

    provide('currentLocale', currentLocale);

    const updateLanguage = (newLocale) => {
      currentLocale.value = newLocale;
    };

    return { currentLocale, updateLanguage };
  }
}
</script>

<style>
#app {
  font-family: Nunito, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
}

h1 {
  font-family: 'Nunito', sans-serif;
  font-weight: 900;
  font-size: 4rem; /* Adjust size as needed */
}

.button {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

.navbar, .navbar * {
  font-family: 'Nunito', sans-serif !important;
}
</style>
