<template>
  <!-- Jumbotron -->
  <div
    class="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center"
    style="background-image: url('https://tecdn.b-cdn.net/img/new/slides/041.webp'); height: 250px">
    <div
      class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
      style="background-color: rgba(0, 0, 0, 0.6)">
      <div class="flex h-full items-center justify-center">
        <div class="text-white">
          <h2 class="mb-4 text-6xl font-semibold">Terms of Service</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- End Jumbotron -->
  
  <!-- Terms of Service Section -->
  <section class="mt-8 p-4">
    <div class="text-center">
      <h3 class="text-3xl font-semibold mb-4">Terms of Service for 'Would You Rather'</h3>
      <article class="max-w-4xl mx-auto text-left">
        <p class="mb-4">Welcome to Would You Rather! By using our website (the "Service"), you agree to these Terms of Service ("Terms"). These Terms apply to all visitors and users of the Service.</p>

        <h4 class="text-2xl font-semibold mb-2">Content and Intellectual Property:</h4>
        <p class="mb-4">All content provided by the Service, including the design, text, graphics, and images, are owned by Would You Rather or its content providers. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service without express written permission from us.</p>

        <h4 class="text-2xl font-semibold mb-2">Links to Third-Party Websites:</h4>
        <p class="mb-4">Our Service may contain links to external websites that are not operated by us. We have no control over, and assume no responsibility for the content, policies, or practices of any third-party websites or services.</p>

        <h4 class="text-2xl font-semibold mb-2">Disclaimers:</h4>
        <p class="mb-4">The Service is provided on an "AS IS" and "AS AVAILABLE" basis. Would You Rather makes no warranties, express or implied, as to the operation of the Service or the information, content, or materials included on the Service.</p>

        <h4 class="text-2xl font-semibold mb-2">Limitation of Liability:</h4>
        <p class="mb-4">Would You Rather will not be liable for any damages of any kind arising from the use of the Service.</p>

        <h4 class="text-2xl font-semibold mb-2">Governing Law:</h4>
        <p class="mb-4">These Terms are governed by the laws of the jurisdiction in which Would You Rather is based, without regard to its conflict of law provisions.</p>

        <h4 class="text-2xl font-semibold mb-2">Changes to Terms:</h4>
        <p class="mb-4">We reserve the right to modify or replace these Terms at any time at our sole discretion. Your continued use of the Service after any changes constitutes your acceptance of the new Terms.</p>

        <h4 class="text-2xl font-semibold mb-2">Contact Us:</h4>
        <p>If you have any questions or suggestions about these Terms, please contact us at hello@codebuzz.eu.</p>
      </article>
    </div>
  </section>
  <!-- End Terms of Service Section -->
</template>

  
  <script>
  export default {
    name: 'TermsOfServiceComponent'
  }
  </script>
  