<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-purple-900 to-indigo-900 p-4 relative overflow-hidden">
    <!-- Animated background -->
    <div class="absolute inset-0">
      <div v-for="i in 50" :key="i" class="star"></div>
    </div>

    <!-- Main content -->
    <div class="relative z-10 w-full max-w-md">
      <!-- Success Message -->
      <div v-if="showSuccessMessage" class="bg-green-500 text-white p-3 rounded-lg animate-fadeOut mb-4 text-center">
        {{ $t('addQuestion.successMessage') }}
      </div>
      <div class="bg-white rounded-lg p-8 shadow-lg w-full max-w-md">
        <h1 class="text-3xl font-bold text-center mb-8">{{ $t('addQuestion.title') }}</h1>
        <form @submit.prevent="submitQuestion">
          <div class="mb-4">
            <label for="option1" class="block text-lg font-semibold mb-2">{{ $t('addQuestion.option1') }}</label>
            <input type="text" id="option1" v-model="option1" required class="w-full px-3 py-2 border rounded shadow-sm" :placeholder="$t('addQuestion.option1Placeholder')" />
          </div>
          <div class="mb-6">
            <label for="option2" class="block text-lg font-semibold mb-2">{{ $t('addQuestion.option2') }}</label>
            <input type="text" id="option2" v-model="option2" required class="w-full px-3 py-2 border rounded shadow-sm" :placeholder="$t('addQuestion.option2Placeholder')" />
          </div>
          <div class="text-center">
            <button type="submit" class="bg-gradient-to-r from-pink-500 to-orange-500 hover:from-pink-600 hover:to-orange-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
              {{ $t('addQuestion.sendButton') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from '@/supabase.js';
export default {
  name: 'AddQuestionComponent',
  data() {
    return {
      option1: '',
      option2: '',
      showSuccessMessage: false
    };
  },
  methods: {
    async submitQuestion() {
      try {
        // Determine the table name based on the current locale
        const tableName = `questions_${this.$i18n.locale}`;

        // Insert the question into the determined table
        const { error } = await supabase.from(tableName).insert([
          {
            option_1: this.option1,
            option_2: this.option2,
            option_1_votes: 0, // Set initial votes to 50
            option_2_votes: 0, // Set initial votes to 50
            published: false, // Set published to false by default
            category: 'default' // Set a default category
          }
        ]);

        if (error) throw error;

        // Clear the form fields after successful submission
        this.option1 = '';
        this.option2 = '';

        // Show success message
        this.showSuccessMessage = true;

        // Hide message after 5 seconds
        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 5000);

      } catch (error) {
        console.error('Error adding question:', error);
        // Handle the error, such as showing an error message
      }
    }
  }
}
</script>

<style scoped>
.bg-gradient-to-br {
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  animation: twinkle 5s infinite;
  opacity: 0;
}

@keyframes twinkle {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.animate-fadeOut {
  animation: fadeOut 5s ease-in-out forwards;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.bg-white {
  background-color: white;
}

.text-white {
  color: white;
}

.font-bold {
  font-weight: bold;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.text-lg {
  font-size: 1.125rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.max-w-md {
  max-width: 28rem;
}

.min-h-screen {
  min-height: 100vh;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.text-center {
  text-align: center;
}

.w-full {
  width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.rounded {
  border-radius: 0.25rem;
}

.border {
  border-width: 1px;
}

.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.bg-green-500 {
  background-color: #48bb78;
}

.hover\:bg-green-600:hover {
  background-color: #38a169;
}

.transition {
  transition: all 0.3s ease;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in-out {
  transition-timing-function: ease-in-out;
}

.text-purple-900 {
  color: #6b46c1;
}

.text-indigo-900 {
  color: #434190;
}
</style>
