import { createApp } from 'vue';
import App from './App.vue';
import './assets/styles.css';
import router from './router';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
import pl from './locales/pl.json';
import pt from './locales/pt.json';
import fr from './locales/fr.json';
import es from './locales/es.json';

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, de, pl, pt, fr, es },
});

const app = createApp(App);

// Simple navigation guard
router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  if (lang && ['en', 'de', 'pl', 'pt', 'fr', 'es'].includes(lang)) {
    i18n.global.locale.value = lang;
  }
  next();
});

app.use(router);
app.use(i18n);
app.mount('#app');