<template>
    <section class="py-12 bg-gray-100">
      <div class="container mx-auto px-6">
        <div v-if="post" class="bg-white shadow-sm rounded-md overflow-hidden p-6">
          <h2 class="text-4xl font-bold text-gray-800 mb-4">{{ post.title }}</h2>
          <img :src="post.featured_image" alt="Blog cover" class="w-full h-56 object-cover object-center mb-6">
          <div class="text-gray-600">
            <div v-html="post.content"></div>
          </div>
        </div>
        <div v-else>
          <p>Loading post...</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { supabase } from '@/supabase.js';
  
  export default {
    name: 'PostComponent',
    data() {
      return {
        post: null
      };
    },
    async created() {
      await this.fetchPost();
    },
    methods: {
      async fetchPost() {
        try {
          const postId = this.$route.params.id; // Assuming you're using Vue Router
          const { data, error } = await supabase
            .from('blog_posts') // Replace with your table name
            .select('*')
            .eq('id', postId)
            .single();
  
          if (error) {
            console.error('Error fetching post:', error);
          } else {
            this.post = data;
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  };
  </script>
  